<template>
  <form autocomplete="off">Update Drugbook Entry
    <h3 v-if="labelVisibility">Edit Record</h3>
    <span class="text-base pt-3">{{ clinic ? clinic.name : "" }}</span>
    <vs-divider v-if="labelVisibility"></vs-divider>
    <div class="p-2">
      <div v-if="record.type === 'discarded'">
        <div class="w-full fieldContainer">
          <div class="w-full fieldContainer">
            <div class="w-full">
              <label v-if="labelVisibility" class="text-sm ml-1 opacity-75"
                >Date discarded</label
              >
              <Datepicker
                format="dd MMMM yyyy"
                v-model="drugbookData.entryDate"
                placeholder="Select Date"
              />
            </div>
          </div>
          <div class="w-full fieldContainer">
            <div class="w-full">
              <vs-input
                :label="labelVisibility ? 'Time Discarded' : ''"
                type="time"
                name="timeAdded"
                data-vv-as="Time Disposed"
                class="w-full"
                v-model="drugbookData.timeAdded"
              />
            </div>
          </div>
          <div class="w-full fieldContainer">
            <div class="w-full">
              <vs-select
                :label="labelVisibility ? 'Drug discarded' : ''"
                class="select"
                width="100%"
                placeholder="Select Drug"
                :danger="errors.first('drug') ? true : false"
                :success="
                  !errors.first('drug') && drugbookData.productId !== ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                v-validate="{ required: true }"
                data-vv-as="drug"
                name="drug"
                autocomplete
                :danger-text="errors.first('drug')"
                v-model="drugbookData.productId"
              >
                <vs-select-item
                  :key="index"
                  :value="item._id"
                  :text="item.name"
                  v-for="(item, index) in drugItems"
                />
              </vs-select>
            </div>
          </div>
          <div class="w-full fieldContainer">
            <div class="w-full">
              <vs-input
                :label="labelVisibility ? 'Notes' : ''"
                data-vv-as="discardReason"
                name="discardReason"
                autocomplete
                placeholder="Reason for discard"
                v-model="drugbookData.discardReason"
                class="w-full"
              />
            </div>
          </div>
          <div class="w-full fieldContainer">
            <div class="w-full">
              <vs-input
                :label="labelVisibility ? 'Amount discarded' : ''"
                :danger="errors.first('discardAmount') ? true : false"
                :success="
                  !errors.first('discardAmount') &&
                  drugbookData.discardAmount !== ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                data-vv-as="discardAmount"
                name="discardAmount"
                v-validate="{
                  required: true,
                  min_value: 0.1,
                }"
                autocomplete
                placeholder="Enter Discard Amount"
                :danger-text="errors.first('discardAmount')"
                v-model.number="formattedAmount"
                :disabled="!drugbookData.productId"
                class="w-full"
                icon-no-border
              />
            </div>
          </div>
          <div class="w-full fieldContainer">
            <div class="w-full">
              <vs-input
                :label="labelVisibility ? 'Batch # - optional' : ''"
                data-vv-as="batchNumber"
                name="batchNumber"
                autocomplete
                type="number"
                placeholder="Batch Number"
                v-model="drugbookData.batchNumber"
                class="w-full"
              />
            </div>
          </div>
          <div class="w-full fieldContainer">
            <div class="w-full">
              <label v-if="labelVisibility" class="text-sm ml-1 opacity-75"
                >Batch expiry - optional</label
              >
              <Datepicker
                format="dd MMMM yyyy"
                v-model="drugbookData.expiryDate"
                placeholder="Batch Expiry (Optional)"
                :typeable="true"
              >
              </Datepicker>
            </div>
          </div>
          <div class="w-full fieldContainer">
            <div class="w-full">
              <vs-input
                :label="labelVisibility ? 'Performed by' : ''"
                name="performedBy"
                v-model="userFullName"
                disabled
                class="w-full"
              />
            </div>
          </div>
        </div>
        <div class="w-full">
          <p class="ml-1">Declaration</p>
          <vs-checkbox
            name="declaration"
            data-vv-as="declaration"
            v-validate="'required'"
            v-model="drugbookData.declaration"
            >I declare that
            <b>{{
              drugbookData.amount && drugDetail
                ? drugbookData.amount + " " + drugDetail.unit
                : "--"
            }}</b>
            of
            <b>{{
              drugbookData.productId && drugDetail ? drugDetail.name : "--"
            }}</b>
            was discarded</vs-checkbox
          >
          <span
            v-if="this.declarationChecker"
            class="con-text-validation span-text-validation-danger vs-input--text-validation-span ml-1"
            >{{ this.declarationChecker }}</span
          >
        </div>
      </div>
      <div v-else-if="record.type === 'used'">
        <div class="w-full fieldContainer">
          <label class="vs-input--label"
            >Date added</label
          >
          <div class="w-full">
            <Datepicker
              format="dd MMMM yyyy"
              v-model="drugbookData.entryDate"
              :input-class="{
                'is-true':
                  !errors.has('drugbookData.entryDate') &&
                  drugbookData.entryDate,
                'is-danger': errors.has('drugbookData.entryDate'),
              }"
              placeholder="Select Date"
            >
              <template slot="afterDateInput">
                <span
                  v-if="drugbookData.entryDate"
                  class="bg-color-done input-icon-validate vs-input--icon-validate"
                >
                  <i
                    valiconpack="material-icons"
                    class="vs-icon notranslate icon-scale material-icons null"
                    >done</i
                  >
                </span>
                <span
                  v-if="errors.has('drugbookData.entryDate')"
                  class="bg-color-error input-icon-validate vs-input--icon-validate"
                >
                  <i
                    valiconpack="material-icons"
                    class="vs-icon notranslate icon-scale material-icons null"
                    >error</i
                  >
                </span>
              </template>
            </Datepicker>
          </div>
        </div>
        <div class="w-full fieldContainer">
          <label class="vs-input--label"
            >Time added</label
          >
          <div class="w-full">
            <!-- label="Time Added" -->

            <vs-input
              type="time"
              name="timeAdded"
              data-vv-as="Time Added"
              class="w-full"
              v-model="drugbookData.timeAdded"
            />
          </div>
        </div>
        <div class="w-full fieldContainer">
          <label class="vs-input--label"
            >Select Drug</label
          >
          <div class="w-full">
            <!-- label="Select Drug" -->

            <vs-select
              class="select"
              width="100%"
              placeholder="Select Drug"
              :danger="errors.first('productId') ? true : false"
              :success="
                !errors.first('productId') && drugbookData.productId !== ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="{ required: true }"
              data-vv-as="productId"
              name="productId"
              autocomplete
              :danger-text="errors.first('productId')"
              v-model="drugbookData.productId"
            >
              <vs-select-item
                :key="index"
                :value="item._id"
                :text="item.name"
                v-for="(item, index) in drugItems"
              />
            </vs-select>
          </div>
        </div>
        <div class="w-full fieldContainer">
          <div class="w-full">
            <vs-input
              :danger="errors.first('drugbookData.amount') ? true : false"
              :danger-text="errors.first('drugbookData.amount')"
              :success="
                !errors.first('drugbookData.amount') &&
                drugbookData.amount != ''
              "
              data-vv-scope="drugbookData"
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="{
                required: true,
                min_value: 0.1,
              }"
              data-vv-as="amount"
              name="amount"
              :label="`Amount Administered (${drugDetail.unit || ''})`"
              placeholder="Amount Administered"
              v-model.number="formattedAmount"
              class="w-full"
            />
          </div>
        </div>
         <div class="w-full fieldContainer">
        <label class="vs-input--label">Notes</label>
        <div class="w-full">
          <vs-input
            name="notes"
            data-vv-as="Notes"
            class="w-full"
            v-model="drugbookData.notes"
          />
        </div>
      </div>
        <div v-if="isFiller" class="w-full fieldContainer">
          <div class="w-full">
            <vs-input
              :danger="errors.first('drugbookData.discardedAmount') ? true : false"
              :danger-text="errors.first('drugbookData.discardedAmount')"
              :success="
                !errors.first('drugbookData.discardedAmount') &&
                drugbookData.discardedAmount != ''
              "
              data-vv-scope="drugbookData"
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="{
                required: true,
                min_value: 0.1,
              }"
              data-vv-as="discardedAmount"
              name="discardedAmount"
              :label=" `Amount Discarded (${drugDetail.unit || ''})`"
              placeholder="Amount Discarded"
              v-model.number="formattedDiscardedAmount"
              class="w-full"
            />
          </div>
        </div>
        <div class="w-full fieldContainer">
          <label class="vs-input--label" >Batch Number</label
          >
          <!-- label="Batch Number" -->

          <div class="w-full">
            <vs-input
              name="batchNumber"
              data-vv-as="Batch Number"
              class="w-full"
              placeholder="Batch Number"
              v-model="drugbookData.batchNumber"
            />
          </div>
        </div>
        <div class="w-full fieldContainer">
          <label class="vs-input--label"
            >Batch Expiry</label
          >
          <!-- label="Batch Expiry" -->

          <div class="w-full">
            <Datepicker
              placeholder="Batch Expiry"
              format="dd MMMM yyyy"
              v-model="drugbookData.expiryDate"
              :typeable="true"
            >
            </Datepicker>
          </div>
        </div>
      </div>
      <div class="p-2" v-else>
        <div class="w-full fieldContainer">
          <label class="vs-input--label" v-if="labelVisibility"
            >Date added</label
          >
          <div class="w-full">
            <Datepicker
              format="dd MMMM yyyy"
              v-model="drugbookData.entryDate"
              :input-class="{
                'is-true':
                  !errors.has('drugbookData.entryDate') &&
                  drugbookData.entryDate,
                'is-danger': errors.has('drugbookData.entryDate'),
              }"
              placeholder="Select Date"
            >
              <template slot="afterDateInput">
                <span
                  v-if="drugbookData.entryDate"
                  class="bg-color-done input-icon-validate vs-input--icon-validate"
                >
                  <i
                    valiconpack="material-icons"
                    class="vs-icon notranslate icon-scale material-icons null"
                    >done</i
                  >
                </span>
                <span
                  v-if="errors.has('drugbookData.entryDate')"
                  class="bg-color-error input-icon-validate vs-input--icon-validate"
                >
                  <i
                    valiconpack="material-icons"
                    class="vs-icon notranslate icon-scale material-icons null"
                    >error</i
                  >
                </span>
              </template>
            </Datepicker>
          </div>
        </div>
        <div class="w-full fieldContainer">
          <label class="vs-input--label" v-if="labelVisibility"
            >Time added</label
          >
          <div class="w-full">
            <!-- label="Time Added" -->

            <vs-input
              type="time"
              name="timeAdded"
              data-vv-as="Time Added"
              class="w-full"
              v-model="drugbookData.timeAdded"
            />
          </div>
        </div>
        <div class="w-full fieldContainer">
          <label class="vs-input--label" v-if="labelVisibility"
            >Select Drug</label
          >
          <div class="w-full">
            <!-- label="Select Drug" -->

            <vs-select
              class="select"
              width="100%"
              placeholder="Select Drug"
              :danger="errors.first('productId') ? true : false"
              :success="
                !errors.first('productId') && drugbookData.productId !== ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="{ required: true }"
              data-vv-as="productId"
              name="productId"
              autocomplete
              :danger-text="errors.first('productId')"
              v-model="drugbookData.productId"
            >
              <vs-select-item
                :key="index"
                :value="item._id"
                :text="item.name"
                v-for="(item, index) in drugItems"
              />
            </vs-select>
          </div>
        </div>
        <div class="w-full fieldContainer">
          <div class="w-full">
            <vs-input
              :danger="errors.first('drugbookData.amount') ? true : false"
              :danger-text="errors.first('drugbookData.amount')"
              :success="
                !errors.first('drugbookData.amount') &&
                drugbookData.amount != ''
              "
              data-vv-scope="drugbookData"
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="{
                required: true,
                min_value: 0.1,
              }"
              data-vv-as="amount"
              name="amount"
              :label="
                labelVisibility
                  ? `Amount Recieved (${drugDetail.unit || ''})`
                  : ''
              "
              placeholder="Amount Recieved"
              v-model.number="formattedAmount"
              class="w-full"
            />
          </div>
        </div>
        <div class="w-full fieldContainer">
          <div class="w-full">
            <vs-input
              :label="labelVisibility ? 'Notes' : ''"
              data-vv-as="notes"
              name="notes"
              autocomplete
              placeholder="Add Notes"
              v-model="drugbookData.notes"
              class="w-full"
            />
          </div>
        </div>
        <div class="w-full fieldContainer">
          <label class="vs-input--label" v-if="labelVisibility"
            >Batch Number</label
          >
          <!-- label="Batch Number" -->

          <div class="w-full">
            <vs-input
              name="batchNumber"
              data-vv-as="Batch Number"
              class="w-full"
              placeholder="Batch Number"
              v-model="drugbookData.batchNumber"
            />
          </div>
        </div>
        <div class="w-full fieldContainer">
          <label class="vs-input--label" v-if="labelVisibility"
            >Batch Expiry</label
          >
          <!-- label="Batch Expiry" -->

          <div class="w-full">
            <Datepicker
              placeholder="Batch Expiry"
              format="dd MMMM yyyy"
              v-model="drugbookData.expiryDate"
              :typeable="true"
            >
            </Datepicker>
          </div>
        </div>
      </div>
      <div class="px-5 flex mt-5">
        <div class="w-1/2 pr-2">
          <vs-button type="border" class="w-full radius" @click="closeModal">
            Cancel
          </vs-button>
        </div>
        <div class="w-1/2 pl-2">
          <vs-button class="w-full radius" @click="editRecord">
            Save
          </vs-button>
        </div>
      </div>
      <div class="px-5 mt-5">
        <div class="w-full pr-2">
          <AreYouSure
            @onConfirm="deleteRecord"
            buttonText="Remove Record"
            popUpTitle="Remove Record"
            popUpMessage="This will create a new entry in your drugbook, and the original will be struck out but still visible for reference.  "
          />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import _ from "lodash";
import Datepicker from "vuejs-datepicker";
import { Validator } from "vee-validate";
import { mapActions } from "vuex";
import moment from "moment";

import AreYouSure from "../../others/areYouSure.vue";

const dict = {
  custom: {
    drug: {
      required: "Please select a drug",
    },
    discardAmount: {
      required: "Please enter an amount",
      min_value: "Please enter a valid amount",
    },
  },
};
Validator.localize("en", dict);
export default {
  props: {
    clinic: {
      type: Object,
    },
    record: {
      type: Object,
    },
    labelVisibility: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Datepicker,
    AreYouSure,
  },
  data: () => ({
    userId: "",
    userFullName: "",
    declarationChecker: "",
    drugDetail: {},
    drugItems: [],
    drugbookData: {},
    isFiller: false,
    appointmentDiscardedDrugbookEntry:{},
  }),
  methods: {
    ...mapActions("drugbook", ["editDrugEntry", "getDrugbookAppointmentDetails"]),
    ...mapActions("product", ["fetchProducts"]),
    getExpiryDate(expiryDate) {
      if (typeof expiryDate == "string" && expiryDate !== ""){
        return moment(expiryDate).format("DD/MM/YYYY")
      }
      return ""
    },
    async getProductsList() {
      try {
        const res = await this.fetchProducts({
          limit: 1000,
        });
        this.drugItems = res.data.data.docs;
        if (this.drugItems.length > 0) {
          this.drugbookData.productId = this.record.productId;
          this.drugDetail = this.drugItems.filter(
            (e) => e._id === this.record.productId
          )[0];
        }
      } catch (error) {
        console.error(error.message);
      }
    },
    async deleteRecord() {
      const payload = {
        clinicId: this.drugbookData.clinicId,
        entryDate: moment(this.drugbookData.entryDate)
          .hour(moment(this.drugbookData.timeAdded, "H:m").get("hour"))
          .minute(moment(this.drugbookData.timeAdded, "H:m").get("minute")),
        productId: this.drugbookData.productId,
        amount: 0,
        performedBy: this.userId,
        batchNumber: this.drugbookData.batchNumber,

        _id: this.record._id,
        discardedAmount: 0,
        area: this.record.area || undefined,
        discardReason: this.drugbookData.discardReason,

        expiryDate: this.getExpiryDate(this.drugbookData.expiryDate)
      };
      try {
        const res = await this.editDrugEntry({ drugEntries: [payload] });
        if (res.status === 200) {
          this.$vs.notify({
            title: "Update drugbook record",
            text: "DrugBook Successfully Updated!",
            color: "success",
          });
          this.$emit("fetchDrugEntries");
          this.$emit("cancelForm");
          this.$validator.reset();
        }
      } catch (error) {
        console.error(error.message);
      }
    },
    async editRecord() {
      let isValid = await this.$validator.validate();
      let checkDeclaration = true;
      if (this.record.type === "discarded") {
        if (!this.drugbookData.declaration) {
          this.declarationChecker =
            "Please check this box if you want to proceed.";
          checkDeclaration = false;
        }
      }
      if (isValid && checkDeclaration) {
        const payload =[]
        if (this.record.type === "used" && this.isFiller) {
          this.appointmentDiscardedDrugbookEntry.amount=this.drugbookData.discardedAmount
          payload.push(appointmentDiscardedDrugbookEntry)
        }
        payload.push({
          clinicId: this.drugbookData.clinicId,
          entryDate: moment(this.drugbookData.entryDate)
            .hour(moment(this.drugbookData.timeAdded, "H:m").get("hour"))
            .minute(moment(this.drugbookData.timeAdded, "H:m").get("minute")),
          productId: this.drugbookData.productId,
          amount: this.drugbookData.amount.toFixed(2),
          notes: this.drugbookData.notes,
          performedBy: this.userId,
          batchNumber: this.drugbookData.batchNumber,

          _id: this.record._id,
          discardedAmount: 0,
          area: this.record.area || undefined,
          discardReason: this.drugbookData.discardReason,

          expiryDate: this.getExpiryDate(this.drugbookData.expiryDate)
        });
        try {
          const res = await this.editDrugEntry({ drugEntries: payload });
          if (res.status === 200) {
            this.$vs.notify({
              title: "Update drugbook record",
              text: "DrugBook Successfully Updated!",
              color: "success",
            });
            this.$emit("fetchDrugEntries");
            this.$emit("cancelForm");
            this.$validator.reset();
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    closeModal() {
      this.$emit("cancelForm");
      this.$validator.reset();
    },
  },
  watch: {
    "drugbookData.productId": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.drugDetail = this.drugItems.filter((e) => e._id === newVal)[0];
      }
    },
    "drugbookData.declaration": {
      handler() {
        this.declarationChecker = "";
      },
    },
  },
  computed: {
    formattedAmount: {
      get: function () {
        const d = Math.pow(10, 2);
        return (
          this.drugbookData.amount &&
          Math.round((this.drugbookData.amount + Number.EPSILON) * d) / d
        );
      },
      set: function (newValue) {
        this.drugbookData.amount = newValue;
      },
    },
    formattedDiscardedAmount: {
      get: function () {
        const d = Math.pow(10, 1);
        return (
          this.drugbookData.discardedAmount &&
          Math.round((this.drugbookData.discardedAmount + Number.EPSILON) * d) / d
        );
      },
      set: function (newValue) {
        this.drugbookData.discardedAmount = newValue;
      },
    },
  },
  async beforeMount() {
    if (this.drugItems.length > 0) {
      this.drugbookData.productId = this.record.productId;
      this.drugDetail = this.drugItems.filter(
        (e) => e._id === this.record.productId
      )[0];
    }
    if(this.record.type==='used'){
      const { data:drugbookAppointment } = await this.getDrugbookAppointmentDetails({appointmentId: this.record.appointmentId});
      this.isFiller = drugbookAppointment.isFiller

      if(this.isFiller && drugbookAppointment.appointmentDrugEntries.length>0){
        this.appointmentDiscardedDrugbookEntry = drugbookAppointment.appointmentDrugEntries[0]
        this.record.discardedAmount = this.appointmentDiscardedDrugbookEntry.amount
      }
    }
    this.getProductsList();
  },
  async created() {
    this.userId = JSON.parse(
      localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
    )._id;
    this.userFullName = `${
      JSON.parse(
        localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
      ).firstName
    } ${
      JSON.parse(
        localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
      ).lastName
    }`;

    if(this.record.discardedAmount){
      this.drugbookData["discardedAmount"] = this.record.discardedAmount;
    }
    await this.getProductsList();
    this.drugbookData = {
      discardReason: this.record.discardReason,
      batchNumber: this.record.batchNumber,
      declaration: this.record.declaration,
      expiryDate: this.record.expiryDate
        ? moment(this.record.expiryDate).format("L")
        : "",
      clinicId: this.record.clinicId,
      entryDate: moment(this.record.entryDate).format("L"),
      amount: this.record.amount,
      notes: this.record.notes,
      productId: this.record.productId,
      timeAdded: moment(this.record.entryDate).format("HH:mm"),
    };
  },
};
</script>

<style></style>
